.page {
	width: 100vw;
	min-height: 820px;
	height: 100vh;
}
.addTodoList {
	min-height: 30%;
	height: fit-content;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: cadetblue;
}

.addContainer {
	width: 70%;
}
.title {
	text-align: center;
}

.input {
	font-size: 1.25rem;
	padding: 0.5rem;
	width: 500px;
}

.showTodoList {
	height: 70%;
	width: 100%;
	overflow: auto;
	display: flex;
	flex-direction: column;
	background-color: lightgrey;
}

@media only screen and (max-width: 1024px) {
	.addContainer {
		width: 80%;
	}
}
@media only screen and (max-width: 768px) {
	.addContainer {
		width: 90%;
	}
}
