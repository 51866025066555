.addComponent {
	display: flex;
	flex-direction: column;
}
.formContainer {
	display: flex;
	justify-content: space-between;
}
.inputField,
.addButton {
	font-size: 1.25rem;
}
.inputField {
	width: 100%;
	padding: 0.5rem;
}
.btnContainer {
	display: flex;
}
.btn {
	padding: 0.5rem 3rem;
}
.showText,
.showNumber {
	width: 100%;
	overflow: auto;
}

@media only screen and (max-width: 1024px) {
	.formContainer {
		flex-direction: column;
	}
	.inputField {
		width: auto;
	}
	.btnContainer {
		padding-top: 1rem;
		justify-content: space-between;
	}
	.btn {
		width: 49%;
	}
}
